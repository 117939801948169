import React from "react";
import { fillCategories, fillSubCategories, fillModels, fillProducts } from "../FillDatabase/FilleDatabse";
import categories from "../../data/Categories";
import data from "../../data/Models";
import { addCategory, addModel, addProduct, addSubCategory } from "./Api";
import Category from "../../models/Category";
import SubCategory from "../../models/SubCategory";


export default function FillDatabaseComponent() {
    const handleFillDatabase = async () => {
        try {
            console.log("🚀 Démarrage du processus d'insertion...");
            
            // Étape 1 : Insérer les catégories
            const categoryMap = {};
            for (const category of categories) {
                console.log(`📂 Ajout de la catégorie : ${category.name}`);
                const categoryId = await addCategory({ id: category.id, name: category.name });
                categoryMap[category.id] = categoryId;
            }

            // Étape 2 : Insérer les sous-catégories
            const subCategoryMap = {};
            for (const category of categories) {
                for (const subCategory of category.subCategories) {
                    console.log(`📂📂 Ajout de la sous-catégorie : ${subCategory.name}`);
                    const subCategoryId = await addSubCategory({
                        categoryName: "Category-" +category.id,
                        subCategory: { id: subCategory.id, name: subCategory.name },
                    });
                    subCategoryMap[subCategory.id] = subCategoryId;
                }
            }

            const extractFileName = (imagePath) => {
                return imagePath ? `/uploads/${imagePath.split('/').pop()}` : null;
            };

            // Étape 3 : Insérer les modèles
            const modelMap = {};
            for (const category of categories) {
                for (const subCategory of category.subCategories) {
                    for (const model of subCategory.models) {
                        console.log(`🖼️ Ajout du modèle : ${model.name}`);

                        // Trouver l'ID de la sous-catégorie
                        const subCategoryId = subCategoryMap[subCategory.id];  
                        if (!subCategoryId) {
                            console.warn(`⚠️ Sous-catégorie introuvable pour le modèle ${model.name}`);
                            continue;
                        }

                        // Ajouter le modèle et récupérer son ID
                        //modelRequest.getCategoryName(), modelRequest.getSubCategoryId(), modelRequest.getModel()
                        const modelId = await addModel({
                            categoryName:"Category-"+category.id,
                            subCategoryId : subCategory.id,
                            model :{
                                id: model.id,
                                name: model.name,
                                image: extractFileName(model.image),
                        }});

                        // Associer le modèle à son ID en base de données
                        modelMap[model.id] = modelId;
                    }
                }
            }






            

            // Étape 4 : Insérer les produits
            // Étape 1 : Construire un mapping des modèles vers leurs produits
            const modelProductsMap = {}; // Stockera les produits par modèle
            for(const model of data.models){
                for (const product of model.products) {
                    if (!modelProductsMap[model.id]) {
                        modelProductsMap[model.id] = [];
                    }
                    modelProductsMap[model.id].push(product);
                }
            }
            

            // Étape 2 : Ajouter les produits en les associant correctement à leur modèle
            for (const category of categories) {
                for (const subCategory of category.subCategories) {
                    for (const model of subCategory.models) {
                        console.log(`🖼️ Vérification du modèle : ${model.name}`);

                        // Trouver l'ID du modèle depuis modelMap
                        const modelId = modelMap[model.id];
                        if (!modelId) {
                            console.warn(`⚠️ Modèle introuvable pour la sous-catégorie ${subCategory.name}`);
                            continue;
                        }

                        // Vérifier si ce modèle a des produits dans modelProductsMap
                        const products = modelProductsMap[model.id] || []; // Récupérer les produits liés à ce modèle
                        console.log(products.length)
                        for (const product of products) {
                            console.log(`📦 Ajout du produit : ${product.name}`);

                            await addProduct({
                                categoryName: `Category-${category.id}`,
                                subCategoryId: subCategory.id,
                                modelId: model.id,
                                product: {
                                    id: product.id,
                                    name: product.name,
                                    image: extractFileName(product.image),
                                    ambianceImage: extractFileName(product.ambianceImage),
                                    description: product.description,
                                    width: product.width,
                                    length: product.length,
                                    thickness: product.thickness,
                                    surface: product.surface,
                                },
                            });
                        }
                    }
                }
            }
            console.log("✅ Base de données remplie avec succès !");
            alert("Base de données remplie avec succès !");
        } catch (error) {
            console.error("❌ Erreur lors du remplissage de la base de données :", error);
            alert("Erreur lors du remplissage de la base de données. Consultez la console.");
        }
    };

    return (
        <div>
            <h2>Fill Database</h2>
            <h2>Fill Database</h2>
            <h2>Fill Database</h2>
            <button onClick={handleFillDatabase}>Start Data Import</button>
        </div>
    );
}